$(() => {
    if ($("[data-page='checklist']").length) {
        const main = document.getElementById('checklist-main');
        main.onload = onInit();

        window.addEventListener('message', (event) => {
            // Value from iFrame
            if (event.origin !== allowedOrigin) {
                console.error('Origin not allowed:', event.origin);
                return;
            }

			if (event.data.action === 'changeUrl') {
				history.pushState('checklist-detail', 'Checklist Detail', `${event.data.path}`);
			}

            var iframe = document.getElementById('checklist-iframe');

			if(!event.data.token) {
				var message = {
					token: clientToken
				};

				iframe.contentWindow.postMessage(message, allowedOrigin);
			} else {
				if(event.data.token !== undefined && event.data.token !== "[object Object]") {
                    setCookie('token', event.data.token, '7')
                }
			}
        });

        //-----FUNCTIONS------

		function setCookie(name, value = '', expire = '', domain = '', path = '/', prefix = '', secure = null, httponly = null) {
			let cookieValue = prefix + name + "=" + encodeURIComponent(value);

			if (expire) {
				let date = new Date();
				date.setTime(date.getTime() + (expire*24*60*60*1000));
				cookieValue += "; expires=" + date.toUTCString();
			}

			if (domain) {
				cookieValue += "; domain=" + domain;
			}

			cookieValue += "; path=" + path;

			if (secure) {
				cookieValue += "; secure";
			}

			document.cookie = cookieValue;
		}

        function onInit() {
		}
    }
})
